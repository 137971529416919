import React, { useEffect } from "react";
import { SCOUTED_COLUMN } from "./Columns";
import { useTable, usePagination, useRowSelect } from "react-table";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
  Select,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  Tag,
  MenuGroup,
  Checkbox,
  Spinner,
  Card,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import { AddIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import Checkbox1 from "./Checkbox";
import { IoMdTrash } from "react-icons/io";
import { format, parse, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";
import { MdArrowDropDown, MdCancelScheduleSend } from "react-icons/md";
import { useMemo, useState } from "react";
// import { BiData } from 'react-icons/bi';
import { data } from "./Columns";
import { TiUserAdd } from "react-icons/ti";
// import { useColorMode } from '@chakra-ui/react';
import ScoutFormModel from "../Modal/ScoutFormModel";
import axios from "axios";
import { useSelector } from "react-redux";
import useRef from "@testing-library/user-event";
import ScoutDeleteDialogeBox from "../Modal/ScoutDeleteDialogeBox";
import { BsFilter, BsSendXFill } from "react-icons/bs";
// import './scouting.css'
import "./Scouting.css";
import ScoutApproveModel from "../Modal/ScoutApproveModel";

const Scouting = () => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const columns = useMemo(() => SCOUTED_COLUMN, []);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [isOpenScoutModel, setIsOpenScoutModel] = useState(false);
  const [scoutData, setScoutData] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  // const previousRowDataRef = useRef({});
  const [editedRowData, setEditedRowData] = useState(null);
  const [lastTapTime, setLastTapTime] = useState(0);
  const toast = useToast();
  const [isOpenDealsDeleteDialogBox, setIsOpenDealsDeleteDialogBox] = useState(false);
  const [result, setResult] = useState(null);
  const [IsOpenScoutApproveModel, setIsOpenApproveScoutModel] = useState(false);
  const [sequences, setSequences] = useState([]);
  const [scoutApproveData, setScoutApproveData] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedResultValue, setSelectedResultValue] = useState("");
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [inputAssignedToValue, setInputAssignedToValue] = useState("");
  const [assignedToData, setAssignedToData] = useState([]);
  const [filteredAssignedToData, setFilteredAssignedToData] = useState([]);
  const [assignedUser, setAssignedUser] = useState([])
  console.log("selected assign to", assignedToData)
  const navigate = useNavigate();

  const routesConfig = [
    {
      path: "/admin",
      role: "Admin",
    },
    {
      path: "/creator",
      role: "Creator Manager",
    },
    {
      path: "/influencer",
      role: "Influencer",
    },
  ];
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");
  const userRoute = routesConfig.find((route) => route.role === role)?.path;

  const { colorMode } = useColorMode();

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: scoutData || [],
      initialState: { pageIndex: 0, pageSize: 15 },
      manualPagination: true,
      pageCount,
    },
    // useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox1 {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox1 {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );
  console.log(
    "headerGroups",
    headerGroups.map((headerGroup) => {
      console.log("page>", page);
      return headerGroup;

      // return headerGroup.headers.map((header) => {
      // return header;
      // });
    })
  );
  const AddCreator = () => {
    setIsOpenScoutModel(true);
    console.log("hello ");
  };

  const fetchScoutData = async () => {
    // try {
    //   const statusParams = selectedStatus.map(status => `status=${status}`).join('&');
    //   const resultParam = `result=${selectedResultValue}`;
    //   const assignParams = selectedAssignedTo.map(assigned_to => `assigned_to=${assigned_to.id}`).join('&');
    //   console.log("fetchSocutData----function")
    //   const response = await fetch(BASE_URL + `/get-scout/?${statusParams}&${assignParams}&${resultParam}&page=${pageIndex + 1}&page_size=${pageSize}`,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   console.log("response", response);
    //   console.log(response.data.paginator, "response from scoute ");
    //   // Process the response
    //   if (response.status === 200) {
    //     setScoutData(response.data.data);
    //     setResult(response.data.data.result);
    //     setPageCount(response.data?.paginator?.total_pages);
    //     const assignedToArray = response.data.data.map(item => item['assigned_to']);
    //     const uniqueAssignedToArray = [...new Set(assignedToArray)];
    //     // setAssignedToData(uniqueAssignedToArray);
    //     setIsLoading(false);
    //     fetchSequences();
    //     console.log("here is 200");
    //   }
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }

    try {
      console.log("fetchScoutFilterData----function")

      const statusParams = selectedStatus.map(status => `status=${status}`).join('&');
      const resultParam = `result=${selectedResultValue}`;
      const assignParams = selectedAssignedTo.map(assigned_to => `assigned_to=${assigned_to.id}`).join('&');
      const response1 = await fetch(BASE_URL + `/get-scout/?${statusParams}&${assignParams}&${resultParam}&page=${pageIndex + 1}&page_size=${pageSize}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response1.ok) {
        const data1 = await response1.json();
        console.log(data1, ">>data1")
        console.log(data1.paginator, "response from scoute ");
        setScoutData(data1.data);
        setResult(data1.data.result);
        setPageCount(data1?.paginator?.total_pages);
        const assignedToArray = data1.data.map(item => item['assigned_to']);
        const uniqueAssignedToArray = [...new Set(assignedToArray)];
        // setAssignedToData(uniqueAssignedToArray);
        setIsLoading(false);
        fetchSequences();
      }
      else {
        toast({
          title: 'Fetch Error.',
          description: 'Failed to fetch scout details',
          status: 'error',
          position: 'bottom',
          duration: 3000,
          isClosable: true,
        })
      }

    }
    catch (error) {
      toast({
        title: 'Fetch Error.',
        description: 'Failed to fetch scout details',
        status: 'error',
        position: 'bottom',
        duration: 3000,
        isClosable: true,
      })
      console.error('Error:', error);
    };
    
  };

  const fetchScoutFilterData = async () => {
    try {
      console.log("fetchScoutFilterData----function")

      const statusParams = selectedStatus.map(status => `status=${status}`).join('&');
      const resultParam = `result=${selectedResultValue}`;
      const assignParams = selectedAssignedTo.map(assigned_to => `assigned_to=${assigned_to.id}`).join('&');
      const response1 = await fetch(BASE_URL + `/get-scout/?${statusParams}&${assignParams}&${resultParam}&page=${pageIndex + 1}&page_size=${pageSize}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response1.ok) {
        const data1 = await response1.json();
        console.log(data1, ">>data1")
        console.log(data1.paginator, "response from scoute ");
        setScoutData(data1.data);
        setResult(data1.data.result);
        setPageCount(data1?.paginator?.total_pages);
        const assignedToArray = data1.data.map(item => item['assigned_to']);
        const uniqueAssignedToArray = [...new Set(assignedToArray)];
        // setAssignedToData(uniqueAssignedToArray);
        setIsLoading(false);
        fetchSequences();
      }
      else {
        toast({
          title: 'Fetch Error.',
          description: 'Failed to fetch scout details',
          status: 'error',
          position: 'bottom',
          duration: 3000,
          isClosable: true,
        })
      }

    }
    catch (error) {
      toast({
        title: 'Fetch Error.',
        description: 'Failed to fetch scout details',
        status: 'error',
        position: 'bottom',
        duration: 3000,
        isClosable: true,
      })
      console.error('Error:', error);
    };
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchScoutFilterData();
      fetchManagerOptions();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedStatus, selectedResultValue, selectedAssignedTo]);

  useEffect(() => {
    fetchScoutData();
    fetchSequences();
    fetchManagerOptions()
  }, []);

  useEffect(() => {
    const timer = setTimeout(async () => {
      await fetchScoutData();
      // await ();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [pageIndex, pageSize]);

  const handleLeftNavigate = () => { };

  const handleRightNavigate = () => { };

  const handleCellClick = (row, cell) => {
    if (
      cell.column.id == "scouted_status" ||
      cell.column.id == "email_sent" ||
      cell.column.id == "contacted" ||
      cell.column.id == 'assigned_to' ||
      cell.column.id == 'total_views'
    ) {
      return;
    } else if (cell.column) {
      setIsEditing(row.original.scout_id);
      const scout_id = row.original.scout_id;

      // let isDate = isValidISODate(row.original.signing_date);

      // if (isDate) {
      //   const parsedDate = parseFormattedDate(row.original.signing_date);
      //   let formattedDate = format(parsedDate, 'yyyy-MM-dd');
      //   setSelectedDate(formattedDate);
      // } else {
      //   setSelectedDate(null);
      // }

      // setEditedRowData(row.original);
      setEditedRowData({
        scout_id,
        ...editedRowData, // Spread existing data
        [cell.column.id]: row.original[cell.column.id], // Update only the specific column
      });
      setEditingCell(cell.column.id);
      // previousRowDataRef.current = editedRowData;
    }
  };

  const handleCellBlur = () => {
    // Auto-save when clicking outside the cell
    if (isEditing) {
      setIsEditing(null);
      setEditingCell(null);
      handleSaveRow();

      // if(managerFlag){
      //   handleSaveManager();
      // }
    }
  };

  const handleSaveRow = async () => {
    if (editedRowData) {
      // if(!editedRowData?.deal_id){
      //   editedRowData.deal_id=dealsId;
      //   editedRowData.influencer=influenserId;
      // }
      console.log("editedrowdata>>", editedRowData);
      try {
        const response = await fetch(BASE_URL + "/update-scout/", {
          method: "PATCH",
          body: JSON.stringify(editedRowData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        if (response.ok) {
          setIsEditing(null);
          setEditedRowData(null);
          fetchScoutData();
          toast({
            title: "Scout Updated",
            description: "Scout updated successfully.",
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Scout Update Failed",
            description: "Failed to update deal details.",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Scout Update Failed",
          description: "Failed to update deal details.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error:", error);
      }
    }
  };

  const handleDeleteRows = async (selectedFlatRows) => {
    console.log("selectedflat", selectedFlatRows);
    try {
      setIsLoading(true);
      const scoutids = selectedFlatRows.map((row) => row?.original?.scout_id);
      const response = await fetch(BASE_URL + "/delete-scout/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ scout_ids: scoutids }),
      });

      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }

      if (response.ok) {
        // setIsLoading(false);

        setScoutData((prevData) =>
          prevData.filter((deal) => !scoutids.includes(deal.scout_id))
        );
        // fetchinfluencerDealsData();
        fetchScoutData();
        toast({
          title: "Deletion Success",
          description: "Creator deleted successfully",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenDealsDeleteDialogBox(false);
      } else {
        toast({
          title: "Deletion Failed",
          description: "Failed to delete Creator details1.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenDealsDeleteDialogBox(false);
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to delete Creator details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      setIsOpenDealsDeleteDialogBox(false);
      console.error("Error:", error);
    }
  };

  const handleApproveModel = () => {
    setIsOpenApproveScoutModel(true);
    console.log("selectedflatsrows", selectedFlatRows);
    setScoutApproveData(true);
  };

  const fetchSequences = async () => {
    // navigate("/admin/sequences/new", { relative: "path" });
    try {
      const response = await fetch(BASE_URL + `/allsequence/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (response.ok) {
        console.log("sequencedata.s", data.Seqeunce);
        setSequences(data.Seqeunce);
      }
    } catch (error) {
      // setIsLoading(false);
      toast({
        title: "Sequences",
        description: "Failed to fetch",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.log("Error:", error);
    }
  };

  const scoutedColumn = selectedFlatRows[0]?.allCells?.find(
    cell => cell.column.Header === 'Scouted'
  );

  const handleCancelScheduleSendFunctionality = async () => {
    try {
      console.log(selectedFlatRows[0].original.scout_id, ">>selectedFlatRows")
      if (selectedFlatRows[0].original.scout_id) {
        const response = await fetch(BASE_URL + `/cancel-schedule-scout/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: selectedFlatRows[0].original.scout_id
          })
        });
        if (response.ok) {
          fetchScoutData();
          const data = await response.json();
          console.log(data, "datadata")
          toast({
            title: "End Scout job",
            description: "Scout job ended successfully",
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
        else {
          toast({
            title: "End Scout job",
            description: "Failed to end",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      }
      else {
        toast({
          title: "End Scout job",
          description: "Scout Id not found",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const handleCheckboxChange = (value) => {
    gotoPage(0);
    const currentIndex = selectedStatus.indexOf(value);
    const newSelected = [...selectedStatus];
    currentIndex === -1 ? newSelected.push(value) : newSelected.splice(currentIndex, 1);
    setSelectedStatus(newSelected);
  };


  const handleAssignedToClick = (assignedTo) => {
    console.log(assignedTo, 'assignedTo');
    gotoPage(0);

    // Check if the item is already in the selectedAssignedTo array
    if (!selectedAssignedTo.some(item => item.id === assignedTo.id)) {
      setSelectedAssignedTo([...selectedAssignedTo, assignedTo]);
    }
  };

  const handleRemoveAssignedTo = (id) => {
    // Filter by id to remove the specific assignedTo by its unique id
    const updatedAssignedToData = selectedAssignedTo.filter((item) => item.id !== id);
    setSelectedAssignedTo(updatedAssignedToData);
  };

  const resetAllFilter = () => {
    setSelectedStatus([]);
  }

  const fetchManagerOptions = async (id) => {
    try {
      const response = await fetch(BASE_URL + `/get-influencer-users/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (data) {
        // setManagerOptions(data.data);
        setAssignedUser(data?.data);
        console.log("manager", data);
        // Map through the data to include first_name, last_name, and id
        const assignedToArrays = data?.data?.map(item => ({
          id: item['id'], // key for id
          first_name: item['first_name'], // key for first_name
          last_name: item['last_name'] // key for last_name
        }));

        // Remove duplicates based on 'first_name', 'last_name', and 'id'
        const uniqueAssignedToArrays = [...new Set(assignedToArrays.map(item => JSON.stringify(item)))]
          .map(item => JSON.parse(item));

        console.log("assignedToArrays", assignedToArrays)
        console.log("uniqueAssignedToArrays", uniqueAssignedToArrays)
        setAssignedToData(uniqueAssignedToArrays);
      }
    } catch (error) {
      console.error("Error fetching Manager Options:", error);
    }
  };

  return (
    <Box px={5} py={3}>
      <SkeletonText
        noOfLines={20}
        spacing="2"
        skeletonHeight="6"
        isLoaded={!isLoading}
      >
        {selectedFlatRows.length > 0 ? (
          <Flex alignItems={"center"} justifyContent={"space-between"} height={'5vh'}>
            <Box>{selectedFlatRows.length} selected</Box>
            <Flex mb={2} padding={2}>
              <Box mr={2}>
                <Button
                  onClick={() => setIsOpenDealsDeleteDialogBox(true)}
                  size={"sm"}
                  colorScheme="red"
                  variant="solid"
                >
                  <IoMdTrash size={20} color="white" />
                </Button>
              </Box>
              {/* Conditionally render content based on selectedFlatRows.length === 1 */}
              {selectedFlatRows.length === 1 && (
                <>
                  {/* If value is empty */}
                  {(!scoutedColumn?.value || scoutedColumn?.value === '') && (
                    <Box>
                      <Button
                        size="sm"
                        colorScheme="green"
                        onClick={() => handleApproveModel()}
                      >
                        <BsSendXFill size={20} color="white" />
                      </Button>
                    </Box>
                  )}

                  {/* If value is Active or Pending */}
                  {(scoutedColumn?.value === 'Active' || scoutedColumn?.value === 'Pending') && (
                    <Box>
                      <Button
                        size="sm"
                        colorScheme="green"
                        onClick={() => handleCancelScheduleSendFunctionality()}
                      >
                        <MdCancelScheduleSend size={20} color="white" />
                      </Button>
                    </Box>
                  )}

                  {/* No button for Ended status */}
                  {scoutedColumn?.value === 'Ended' && null}
                </>
              )}

            </Flex>
          </Flex>
        ) : (
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            {/* <Box>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search..."
                  width={"350px"}
                  borderRadius={"100vh"}
                />
                <InputRightElement cursor={"pointer"}>
                  <CloseIcon w={3} h={3} color="gray.400" />
                </InputRightElement>
              </InputGroup>
            </Box> */}
            <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    height={"2rem"}
                    minWidth={"2.3rem"}
                    as={IconButton}
                    aria-label='Options'
                    icon={<BsFilter size={18} />}
                    variant='outline'
                  />
                  <MenuList>
                    <Flex flexDirection={"column"}>
                      <Flex className='flex-none ' justifyContent={"space-between"}>
                        <MenuGroup>
                          <Flex flexDirection={"column"}>
                            <Flex w={"320px"} px={3} pb={2} alignItems={"center"} justifyContent={"space-between"}>
                              <Text fontSize={"sm"} fontWeight={600}>Status</Text>
                              {
                                selectedStatus.length > 0 &&
                                <Tag borderRadius='full' size={"sm"} colorScheme='red' variant='outline' cursor={"pointer"} onClick={() => setSelectedStatus([])}>{selectedStatus.length > 0 && `Clear (${selectedStatus.length})`}</Tag>
                              }
                            </Flex>
                            <Flex px={3} gap={5}>
                              <Flex flexDirection={"column"} gap={1}>
                                <Checkbox value='Active' colorScheme='green' onChange={() => handleCheckboxChange('Active')} isChecked={selectedStatus.includes('Active')}>Active</Checkbox>
                              </Flex>
                              <Flex flexDirection={"column"} gap={1}>
                                <Checkbox value='Pending' colorScheme='green' onChange={() => handleCheckboxChange('Pending')} isChecked={selectedStatus.includes('Pending')}>Pending</Checkbox>
                              </Flex>
                              <Flex flexDirection={"column"} gap={1}>
                                <Checkbox value='Ended' colorScheme='green' onChange={() => handleCheckboxChange('Ended')} isChecked={selectedStatus.includes('Ended')}>Ended</Checkbox>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Flex flexDirection={"column"} pt={5}>
                            <Flex w={"320px"} px={3} pb={2} alignItems={"center"} justifyContent={"space-between"}>
                              <Text fontSize={"sm"} fontWeight={600}>Result</Text>
                              {
                                selectedResultValue &&
                                <Tag borderRadius='full' size={"sm"} colorScheme='red' variant='outline' cursor={"pointer"}
                                  onClick={() => {
                                    setSelectedResultValue("");
                                  }
                                  }>{selectedResultValue && `Clear 1`}</Tag>
                              }
                            </Flex>
                            <Flex px={3} gap={5}>
                              <Select
                                name="account_type"
                                className="custom-dropdown"
                                style={{ resize: "both", minWidth: "200px" }}
                                fontSize="sm"
                                value={selectedResultValue}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  gotoPage(0);
                                  setSelectedResultValue(value)
                                  console.log(value, ">>value")
                                }}
                                onBlur={() => handleCellBlur()}
                                icon={<MdArrowDropDown />}
                                placeholder="Select result"
                                _focus={{ zIndex: "0" }}
                              >
                                <option value="Signed">Signed</option>
                                <option value="Not interested">
                                  Not interested
                                </option>
                                <option value="No Response">
                                  No Response
                                </option>
                                <option value="Interested">
                                  Interested
                                </option>
                              </Select>
                            </Flex>
                          </Flex>

                          <Flex flexDirection={"column"} pt={5}>
                            <Flex w={"320px"} px={3} pb={2} alignItems={"center"} justifyContent={"space-between"}>
                              <Text fontSize={"sm"} fontWeight={600}>Assigned To</Text>
                              {
                                selectedAssignedTo.length > 0 &&
                                <Tag borderRadius='full' size={"sm"} colorScheme='red' variant='outline' cursor={"pointer"} onClick={() => {
                                  setInputAssignedToValue('')
                                  setSelectedAssignedTo([])
                                }
                                }>{selectedAssignedTo.length > 0 && `Clear (${selectedAssignedTo.length})`}</Tag>
                              }
                            </Flex>
                            <Box px={3}>
                              <InputGroup>
                                <Input w={"300px"} value={inputAssignedToValue} onChange={(e) => {
                                  setInputAssignedToValue(e.target.value);
                                  gotoPage(0);
                                  const filteredData = assignedToData?.filter(assignedTo =>
                                    (assignedTo.first_name.toLowerCase() + " " + assignedTo.last_name.toLowerCase())
                                      .includes(e.target.value.toLowerCase())
                                  );
                                  setFilteredAssignedToData(filteredData);
                                }}
                                  placeholder='Search for assigned to...' />
                                {inputAssignedToValue ? (
                                  <InputRightElement>
                                    <CloseIcon cursor={"pointer"} w={2.5} h={2.5} color='gray.400' onClick={() => setInputAssignedToValue("")} />
                                  </InputRightElement>
                                ) : (
                                  <InputRightElement>
                                    <SearchIcon w={3} h={3} color='gray.400' />
                                  </InputRightElement>
                                )
                                }
                              </InputGroup>

                              {
                                inputAssignedToValue &&
                                <Card mt={1}>
                                  {filteredAssignedToData?.length > 0 && (
                                    <Box my={1} maxH={"145px"} overflowY={"auto"} sx={{
                                      "&::-webkit-scrollbar": { width: "4px" },
                                      "&::-webkit-scrollbar-thumb": { background: "#dddddd", borderRadius: "100vh" },
                                    }}>
                                      {filteredAssignedToData?.map((assignedTo, index) => (
                                        <Text px={3} py={1} fontSize={"sm"} _hover={{ bg: "#EDF2F7", cursor: "pointer" }} key={assignedTo.id} onClick={() => handleAssignedToClick(assignedTo)}>
                                          {/* Display first_name and last_name */}
                                          {assignedTo.first_name} {assignedTo.last_name}
                                        </Text>
                                      ))}
                                    </Box>
                                  )}
                                </Card>
                              }

                              <Box mt={1} maxW={"300px"} maxH={"55px"} overflow={"auto"} sx={{
                                "&::-webkit-scrollbar": { width: "3px" },
                                "&::-webkit-scrollbar-track": { background: "#f1f1f1", borderRadius: "10px" },
                                "&::-webkit-scrollbar-thumb": { background: "#888", borderRadius: "10px" },
                              }}>
                                {
                                  selectedAssignedTo?.map((value, index) => (
                                    <Tag mr={1} mb={1} key={index} size="md" variant="subtle" colorScheme="blackAlpha">
                                      <TagLabel>{value.first_name} {value.last_name}</TagLabel>
                                      <TagCloseButton onClick={() => handleRemoveAssignedTo(value.id)} />
                                    </Tag>
                                  ))
                                }
                              </Box>
                            </Box>
                          </Flex>

                        </MenuGroup>

                      </Flex>
                    </Flex>
                  </MenuList>
                </Menu>
              </Box>
            </Flex>

            <Button
              size={"sm"}
              colorScheme="blue"
              variant="solid"
              onClick={AddCreator}
            >
              <TiUserAdd />
            </Button>
          </Flex>
        )}
        <Box
          fontSize={14}
          mb={1}
          boxShadow="xs"
          h={"calc(100vh - 180px)"}
          w={"calc(100vw - 290px)"}
          rounded={"md"}
          sx={{ position: "relative" }}
          className="here scoting_height"
        >
          <TableContainer
            {...getTableProps()}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "scroll",
              "&::-webkit-scrollbar": { height: "10px", width: "0px" },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#3182ce",
                borderRadius: "10px",
              },
            }}
          >
            <Table variant="simple" width={"100%"}>
              <Thead bg={"green.400"} className="fix-header">
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                        <Flex alignItems={"center"}>
                          {column.render("Header")}
                          <Box>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <HiArrowDown />
                              ) : (
                                <HiArrowUp />
                              )
                            ) : (
                              ""
                            )}
                          </Box>
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>

              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  console.log("row", row);
                  const isRowEditing = isEditing === row?.original?.scout_id;

                  return (
                    <Tr
                      {...row.getRowProps()}
                      _hover={{
                        bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                        boxShadow:
                          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      }}
                    >
                      {row.cells.map((cell) => {
                        // console.log("celling", cell);

                        const isCellEditing =
                          isRowEditing && editingCell === cell.column.id;
                        return (
                          <Td
                            {...cell.getCellProps()}
                            onTouchEnd={(e) => {
                              // Check if the touch event is a double-tap
                              if (e.timeStamp - lastTapTime < 600) {
                                handleCellClick(row, cell);
                              }
                              setLastTapTime(e.timeStamp);
                            }}
                            onDoubleClick={() => handleCellClick(row, cell)}
                          >
                            {cell.column.id === "selection" ? (
                              <Checkbox1
                                {...row.getToggleRowSelectedProps()}
                              // disabled={isRowEditing}
                              />
                            ) : cell.column.Header === "Created At" ? (
                              format(
                                new Date(row.original.created_ts),
                                "MM/dd/yyyy HH:mm:ss"
                              )
                            ) : cell.column.id === "partners_domain_name" ? (
                              cell.render("Cell")
                            ) : cell.column.id === "whitelisted_company_ids" ? (
                              cell.render("Cell")
                            ) : cell.column.id === "blacklisted_company_ids" ? (
                              cell.render("Cell")
                            ) : isCellEditing ? (
                              cell.column.id === "result" ? (
                                <Select
                                  name="account_type"
                                  className="custom-dropdown"
                                  style={{ resize: "both", minWidth: "200px" }}
                                  fontSize="sm"
                                  onChange={(e) => {
                                    const fieldName = cell.column.id;
                                    const value = e.target.value;
                                    const selectedOption =
                                      e.target.options[e.target.selectedIndex];
                                    const selectedId = selectedOption.id;
                                    console.log("row>>", row);

                                    row.values[fieldName] = value;
                                    // setManager({
                                    //   influencer_id: row.original.influencer_id,
                                    //   manager_id: selectedId, // Make sure 'key' is defined and correct
                                    // });
                                    setEditedRowData({
                                      ...editedRowData,
                                      [fieldName]: value,
                                    });
                                  }}
                                  onBlur={() => handleCellBlur()}
                                  icon={<MdArrowDropDown />}
                                  // value={userData.account_type}
                                  placeholder="Select result"
                                  _focus={{ zIndex: "0" }}
                                >
                                  <option value="Signed">Signed</option>
                                  <option value="Not interested">
                                    Not interested
                                  </option>
                                  <option value="No Response">
                                    No Response
                                  </option>
                                  <option value="Interested">
                                  Interested
                                </option>
                                </Select>
                              ) : cell.column.id === "signing_date" ? (
                                <FormControl>
                                  <Box p={4}>
                                    <Input
                                      type="date"
                                      label="Select Date"
                                      name={cell.column.id}
                                      // value={selectedDate}
                                      // onChange={handleDateChange}
                                      onBlur={() => handleCellBlur()}
                                      // icon={<MdArrowDropDown />}

                                      _focus={{ zIndex: "0" }}
                                    />
                                  </Box>
                                </FormControl>
                              ) : (
                                <Textarea
                                  style={{ resize: "both", minWidth: "250px" }}
                                  // w="250px"
                                  type="text"
                                  value={row.values[cell.column.id]}
                                  onChange={(e) => {
                                    const fieldName = cell.column.id;
                                    const value = e.target.value;
                                    if (
                                      fieldName === "meta_tags" ||
                                      fieldName ===
                                      "whitelisted_company_domains" ||
                                      fieldName === "partners_domain_name"
                                    ) {
                                      const metaTagsArray = value
                                        .split(",")
                                        .map((tag) => tag);
                                      row.values[fieldName] = metaTagsArray;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: metaTagsArray,
                                      });
                                    } else {
                                      row.values[fieldName] = value;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: value,
                                      });
                                    }
                                  }}
                                  onBlur={() => handleCellBlur()} // Auto-save on input blur
                                />
                              )
                            ) : (
                              cell.render("Cell")
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <Flex className="pagination-box padding-for-pagination" alignItems={"center"} justifyContent={"space-between"} mt={3}>
          <Flex >
            Page&nbsp;
            <Text fontWeight={500}>
              {pageIndex + 1} of {pageCount}
            </Text>
          </Flex>
          <Flex alignItems={"center"} gap={3}>
            <Box
              _hover={{
                bg: "#EDF2F7",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronLeft onClick={() => previousPage()} size={24} />
            </Box>
            <Box
              _hover={{
                bg: "#EDF2F7",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            // onClick={handleRightNavigate}
            >
              <GoChevronRight onClick={() => nextPage()} size={24} />
            </Box>
          </Flex>
        </Flex>
      </SkeletonText>
      <ScoutFormModel
        isOpen={isOpenScoutModel}
        onClose={() => {
          setIsOpenScoutModel(false);
        }}
        fetchScoutData={fetchScoutData}
      />
      <ScoutDeleteDialogeBox
        isOpen={isOpenDealsDeleteDialogBox}
        onClose={() => {
          setIsOpenDealsDeleteDialogBox(false);
        }}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        handleDeleteRows={handleDeleteRows}
      />
      {scoutApproveData && (
        <ScoutApproveModel
          sequences={sequences}
          isOpen={IsOpenScoutApproveModel}
          // onClose={()=>{setIsOpenApproveScoutModel(false)}}
          setIsOpenApproveScoutModel={setIsOpenApproveScoutModel}
          selectedFlatRows={selectedFlatRows}
          fetchScoutData={fetchScoutData}
        />
      )}
    </Box>
  );
};

export default Scouting;
