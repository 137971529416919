import React, { useState } from "react";
import {
  GoogleLogin,
  useGoogleLogin,
  hasGrantedAllScopesGoogle,
} from "@react-oauth/google";
import { Button, useToast } from "@chakra-ui/react";
import {
  MdEmail,
  MdOutlineAlternateEmail,
  MdOutlineEmail,
} from "react-icons/md";

export const GmailAuth = ({ payloadvalue, fetchEmails }) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const [tokenResponse, setTokenResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const scopes = [
    "https://www.googleapis.com/auth/gmail.send",
    "https://www.googleapis.com/auth/gmail.modify",
    "https://www.googleapis.com/auth/gmail.compose",
    "https://www.googleapis.com/auth/gmail.readonly",
    "https://www.googleapis.com/auth/pubsub",
  ];

  const handleSuccess = (codeResponse) => {
    // console.log(codeResponse);
    setTokenResponse(codeResponse);
  };

  const token = localStorage.getItem("token");
  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope: scopes.join(" "),
    // redirect_uri: 'http://localhost:3000/',
    // ux_mode : 'redirect',
    onSuccess: async (codeResponse) => {
      setIsLoading(true);
      console.log(codeResponse);
      try {
        const response = await fetch(BASE_URL + "/add_user_emails/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            code: codeResponse.code,
            payloadvalue,
          }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log(response, "response is here");
        const res = await response.json();
        fetchEmails();
        console.log(res, "response after add gamail");

        if (response.status === 201) {
          console.log("if");
          toast({
            title: "Email Verification",
            description: res.message,
            status: "success",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
          window.location.reload();
        } else {
          console.log("else");
          toast({
            title: "Email Verification",
            description: res.message || "Unknown response",
            status: "info",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.log("catch");
        setIsLoading(false);
        console.error("Error:", error);
        toast({
          title: "Email Verification",
          description: "something went wrong!",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  // const login = useGoogleLogin({
  //     onSuccess: (codeResponse) => handleSuccess(codeResponse),
  //     // flow: 'auth-code',
  //     });
  const hasAccess = hasGrantedAllScopesGoogle(
    tokenResponse,
    scopes[0],
    scopes[1],
    scopes[2]
  );
  console.log("hasAccess", hasAccess);
  return (
    <div>
      <Button
        display={"flex"}
        isLoading={isLoading}
        onClick={() => googleLogin()}
      >
        <MdOutlineAlternateEmail size={22} color="#00AA45" />
        Add Emails{" "}
      </Button>
    </div>
  );
};

export default GmailAuth;
