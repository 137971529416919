import { Divider, Button, Flex, useToast, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { GoChevronLeft } from "react-icons/go";
import { FaFilePdf } from "react-icons/fa";
import { MdArchive, MdVerifiedUser } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from "@chakra-ui/react";
import { AttachmentIcon, ChevronDownIcon } from "@chakra-ui/icons";
import "./InboxCardModel.css";
import InboxAllEmails from "../Table/InboxAllEmails";
import ReplyModal from "./emailReplyPopup";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import AllEmailReply from "./EmailAllReply";
import { BiReplyAll } from "react-icons/bi";
import { BiReply } from "react-icons/bi";
import { useMediaQuery } from "@chakra-ui/react";

const AllMessegesModel = ({
  allEmailsData,
  receivedData,
  messages,
  setMesseges,
  threadId,
  emailData,
  isActive,
  setIsActive,
  selectedEmail,
  isNonGeneral,
  handleGetEmail,
  handleTalentEmails,
  currentEmail,
  setPageIndex,
  handleTabs,
  getAllMessagesAPI
}) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isAllReplyOpen, setIsAllReplyOpen] = useState(false);
  const [isPencilClick, SetIsPencilClick] = useState(false);

  const [openAccordionEmail, setOpenAccordionEmail] = useState([])
  // const [messages, setMesseges] = useState(null);
  const modalRef = useRef(null);
  const [isMobile] = useMediaQuery("(max-width: 768px)");


  const routesConfig = [
    { path: "/admin", role: "Admin" },
    { path: "/creator", role: "Creator Manager" },
    { path: "/influencer", role: "Influencer" },
  ];

  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");

  const userRoute = routesConfig.find((route) => route.role === role)?.path;

  const archiveMessage = async () => {
    try {
      const emailId = selectedEmail
        ? selectedEmail
        : localStorage.getItem("userEmail");
      const threadIds = messages.map((message) => message.thread_id);
      const response = await fetch(BASE_URL + "/archieve-message/", {
        method: "POST",
        body: JSON.stringify({
          inbox_email: emailId,
          message_id: threadIds,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.status === 200) {
        toast({
          title: "Message archived successfully",
          description: "message has been succesfully archived",
          duration: 3000,
          isClosable: true,
          position: "bottom",
          status: "success",
        });

        if (!isNonGeneral) {
          // handleGetEmail("", "unread");
          // path = `${userRoute}/${Currenttalentname}General-Inbox/Inbox`;
          // window.location.href()
          // window.location.reload();
          // setIsActive("Unread")
          // setPageIndex(0);
          handleTabs("Unread");
          setMesseges(null);
        } else {
          // window.location.reload();
          setMesseges(null);
          // handleTalentEmails("unread");
          handleTabs("Unread");
        }
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDownload = async (attachment) => {
    const res = await fetch(attachment.data, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.ok) {
      const blob = await res.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = attachment?.filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast({
        title: "Dowloaded Successfully",
        description: attachment.filename,
        status: "success",
        position: "bottom",
        duration: 2000,
        isClosable: true,
      });
    } else {
      console.error("Download failed:", res.status, res.statusText);
    }
  };

  const HandleExit = () => {
    // navigate(`${userRoute}/InboxAllEmails`,{state:receivedData})
    setMesseges(null);
    setIsActive(isActive);
  };

  const formatMessageSnippet = (snippet) => {
    const sanitizedSnippet = DOMPurify.sanitize(snippet);
    return sanitizedSnippet
      .replace(
        /<a href="([^"]+)">([^<]+)<\/a>/g,
        `<span style="color: blue; text-decoration: underline;"><a href="$1">$2</a></span>`
      )
      .replace(/<br\s*\/?>/gi, "<br />")
      .replace(/white-space:pre[^"]*"/g, '');
  };

  const extractEmail = (emailString) => {
    const emailRegex = /<([^>]+)>/;
    const match = emailString.match(emailRegex);
    if (match) {
        return match[1];
    }
    return emailString;
}

  const handleReplyClick = (text) => {
    if (text === "reply-all") {
      SetIsPencilClick(true);
      setIsAllReplyOpen(true);
    } else {
      setIsOpen(true);
      setIsAllReplyOpen(false);
    }
    setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.scrollIntoView({ behavior: "smooth" }); 
      }
    }, 100);
  };

  const handleDeleteAllMessage = async () => {
    const threadIds = emailData.thread_id;
    const thread_id = emailData?.messages?.map((item) => item.thread_id);
    try {
      const response = await fetch(BASE_URL + "/trash-message/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          message_id: thread_id,
          thread_id: [threadIds],
        }),
      });
      const res = await response.json();
      // console.log(res, "res");
      if (
        res.success &&
        res.success.trim() ===
        "Message moved to trash and thread deleted successfully."
      ) {
        toast({
          title: "Message moved to trash and thread deleted successfully",
          description: "Message moved to trash and thread deleted successfully",
          status: "success",
          position: "bottom",
          duration: 2000,
          isClosable: true,
        });

        if (isNonGeneral) {
          setMesseges("null");
          handleTabs("Unread");
          // handleTalentEmails("unread");
        } else {
          setMesseges("null");
          handleTabs("Unread");
          // handleGetEmail("", "unread");
        }
        setMesseges(null);
      } else {
        toast({
          title: "Something went wrong",
          description: "Unexpected response received.",
          status: "error",
          position: "bottom",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log("Error delete", error.message);
      toast({
        title: "Something went wrong",
        description: "Error during the deletion process.",
        status: "error",
        position: "bottom",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(()=> {
    if(!openAccordionEmail.includes(messages.length - 1)){
      setOpenAccordionEmail([...openAccordionEmail, messages.length - 1])
    }
  }, [messages])

  return (
    <div className="reply_box_outer">
      {messages === null ? (
        <InboxAllEmails allEmailsData={allEmailsData} />
      ) : (
        <>
          <div className="back_arrow_design">
            <div className="button-divider-container">
              <div className="back_handel">
                <Button onClick={HandleExit}>
                  <GoChevronLeft size={24} />
                </Button>
                <Text className="all_emails_main_text">
                  {" "}
                  {messages[0]?.subject}{" "}
                </Text>{" "}
              </div>
              {/* <BsPencilSquare
                onClick={() => { handleReplyClick(); SetIsPencilClick(true) }}
                cursor="pointer" style={{ marginLeft: "1380px" }} /> */}
              <div className="delete_archive_icons">
                <MdDelete
                  color="red"
                  cursor="pointer"
                  onClick={handleDeleteAllMessage}
                />
                <MdArchive cursor="pointer" onClick={archiveMessage} />
              </div>
            </div>
          </div>


          <Accordion
            index={openAccordionEmail}
            allowMultiple
            className="acc_main_top"
          >
            {!isMobile || (!isOpen && !isAllReplyOpen) ? (
              <>
                {messages.map((message, index) => {
                  return (
                    <>
                      <AccordionItem className="acc-item-main email_reply_sec">
                        <h2 
                          onClick={() => {
                            if(openAccordionEmail.includes(index)){
                              setOpenAccordionEmail(openAccordionEmail.filter(item => item!== index));
                            } else {
                              setOpenAccordionEmail([...openAccordionEmail, index]);
                            }
                          }}
                        >
                          <AccordionButton pb={7} p={0} bg="transparent" _hover={{ bg: 'transparent' }}>
                            <Box
                              as="span"
                              flex="1"
                              textAlign="left"
                              style={{ fontWeight: "bold" }}
                            >
                              <div className="accordion_button_bar">
                                <span
                                  style={{ display: "flex", alignItems: "center" }}
                                >
                                  {message.from_email.split("<")[0].trim()}
                                  <span>
                                    {message.in_prospect && (
                                      <MdVerifiedUser
                                        style={{
                                          fontSize: "24px",
                                          color: "#4483b3",
                                        }}
                                      />
                                    )}
                                  </span>
                                </span>
                                {message?.attachments?.length > 0 && (
                                  <AttachmentIcon />
                                )}
                              </div>
                            </Box>
                            <Box>{message.created_at}</Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>

                        <Popover className="detail_drop_area">
                          <PopoverTrigger >
                            <Button
                              bg="white"
                              _hover={{ bg: 'transparent' }}
                              _active={{ bg: 'transparent' }}
                              variant="link"
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                              padding="10px"
                            >
                              <span style={{ display: 'flex', marginTop: '-27px', color: 'rgb(153, 151, 151)', fontSize: '14px' }}>
                                {message.cc && message.cc.trim() ? (
                                  <>
                                    <span>{message.cc.replace(/<[^>]*>/g, '')}</span>
                                    <IoMdArrowDropdown style={{ marginTop: '3px' }} />
                                  </>
                                ) : extractEmail(message.from_email).toLocaleLowerCase() === localStorage.getItem('userEmail').toLocaleLowerCase() ? (
                                  <>
                                    <span>to {message.to.replace(/<[^>]*>/g, '')}</span>
                                    <IoMdArrowDropdown style={{ marginTop: '3px' }} />
                                  </>
                                ) : (
                                  <>
                                    <span>to me 
                                      {message.to.split(', ').map((singleEmail) =>{
                                        if(!singleEmail?.match(/<([^>]+)>/) || !singleEmail?.match(/<([^>]+)>/)[1]){
                                          return "";
                                        }
                                        const email = singleEmail?.match(/<([^>]+)>/)[1];
                                        if(!email){
                                          return "";
                                        }
                                        if(email.toLocaleLowerCase() === localStorage.getItem('userEmail').toLocaleLowerCase()){
                                          return "";
                                        }
                                        return `, ${singleEmail.replace(/<[^>]*>/g, '')}`
                                      }
                                      )}
                                    </span>
                                    <IoMdArrowDropdown style={{ marginTop: '3px' }} />
                                  </>
                                )}
                              </span>
                            </Button>
                          </PopoverTrigger>

                          {/* Popover Content with message details */}
                          <PopoverContent
                            w="250px"
                            boxShadow="md"
                            borderRadius="8px"
                            p="10px"
                            zIndex={100}
                            bg="white"
                            mt={2}
                            className="detail_drop_content_area"
                          >

                            <PopoverBody bg="white" className="detail_drop_content_body" >
                              <div style={{ marginBottom: '8px' }}>
                                <span style={{ color: 'rgb(139, 139, 139)' }} className="detail_drop_content_heading" >from: </span>
                                <span style={{ fontSize: '14px' }} className="detail_drop_content_text">{message.from_email}</span>
                              </div>

                              {message.to && (
                                <div style={{ marginBottom: '8px' }}>
                                  <span style={{ color: 'rgb(139, 139, 139)' }} className="detail_drop_content_heading" >to: </span>
                                  <span style={{ fontSize: '14px' }} className="detail_drop_content_text">
                                    {message.to.split(',').map((email, index) => (
                                      <div key={index} style={{ display: 'inline' }}>
                                        {email.trim()}
                                      </div>
                                    ))}
                                  </span>
                                </div>
                              )}

                              {message.cc && message.cc.trim() && (
                                <div style={{ marginBottom: '8px' }}>
                                  <span style={{ color: 'rgb(139, 139, 139)' }} className="detail_drop_content_heading" >cc: </span>
                                  <span style={{ fontSize: '14px' }} className="detail_drop_content_text">
                                    {message.cc.split(',').map((email, index) => (
                                      <div key={index} style={{ display: 'inline' }}>
                                        {email.trim()}
                                      </div>
                                    ))}
                                  </span>
                                </div>
                              )}

                              {message.created_at && (
                                <div style={{ marginBottom: '8px' }}>
                                  <span style={{ color: 'rgb(139, 139, 139)' }} className="detail_drop_content_heading" >date: </span>
                                  <span style={{ fontSize: '14px' }} className="detail_drop_content_text">{message.created_at}</span>
                                </div>
                              )}

                              {message.subject && (
                                <div style={{ marginBottom: '8px' }}>
                                  <span style={{ color: 'rgb(139, 139, 139)' }} className="detail_drop_content_heading" >subject: </span>
                                  <span style={{ fontSize: '14px' }} className="detail_drop_content_text">{message.subject}</span>
                                </div>
                              )}

                              {message.bcc && message.bcc.trim() && (
                                <div style={{ marginBottom: '8px' }}>
                                  <span style={{ color: 'rgb(139, 139, 139)' }} className="detail_drop_content_heading" >bcc: </span>
                                  <span style={{ fontSize: '14px' }} className="detail_drop_content_text">{message.bcc}</span>
                                </div>
                              )}
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>

                        <AccordionPanel pb={6}>
                          {/* Use dangerouslySetInnerHTML to render HTML */}

                          {/* <Box dangerouslySetInnerHTML={{ __html: message.snippet }} /> */}
                          <Box
                            className="custom-url-color"
                            dangerouslySetInnerHTML={{
                              __html: formatMessageSnippet(message.snippet),
                            }}
                            style={{ whiteSpace: "pre-wrap" }}
                          />

                          <Divider />
                          <Flex>
                            {message.attachments.map((attachment) => (
                              <Flex
                                direction="column"
                                align="center"
                                justify="center"
                                border="1px solid"
                                borderColor="gray.200"
                                borderRadius="md"
                                p={4}
                                w="120px"
                                h="150px"
                                m={2}
                              >
                                <Box
                                  as={FaFilePdf}
                                  cursor={"pointer"}
                                  size="40px"
                                  color="red.500"
                                  onClick={() => {
                                    handleDownload(attachment);
                                  }}
                                />
                                <Text
                                  onClick={() => {
                                    handleDownload(attachment);
                                  }}
                                  fontSize="sm"
                                  mt={2}
                                  textAlign="center"
                                  noOfLines={2}
                                  cursor={"pointer"}
                                  isTruncated
                                  maxW="100px"
                                >
                                  {attachment?.filename}
                                </Text>
                              </Flex>
                            ))}
                          </Flex>
                        </AccordionPanel>
                      </AccordionItem>
                    </>
                  );
                })}
              </>
            ) : null}
            <div className="reply_modal" ref={modalRef}>
              {isOpen && (
                <ReplyModal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  message={messages}
                  isNonGeneral={isNonGeneral}
                  currentEmail={currentEmail}
                  threadId={threadId}
                  getAllMessagesAPI={getAllMessagesAPI}
                />
              )}
            </div>

            <div className="all_reply_modal">
              {!isOpen && isAllReplyOpen && (
                <AllEmailReply
                  isAllReplyOpen={isAllReplyOpen}
                  setIsAllReplyOpen={setIsAllReplyOpen}
                  message={messages}
                  isNonGeneral={isNonGeneral}
                  currentEmail={currentEmail}
                  threadId={threadId}
                  isPencilClick={isPencilClick}
                  getAllMessagesAPI={getAllMessagesAPI}
                />
              )}
            </div>
          </Accordion>



          {!isOpen && !isAllReplyOpen && (
            <div className="reply_all_btns">
              <div
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                  marginLeft: "20px",
                }}
              >
                <button
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px 15px",
                    borderRadius: "4px",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  }}
                  onClick={() => handleReplyClick("reply")}
                >
                  <span style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                    <BiReply fontSize="24px" />
                    Reply
                  </span>
                </button>
              </div>
              <div style={{ display: "inline-block" }}>
                <button
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px 15px",
                    borderRadius: "4px",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  }}
                  onClick={() => handleReplyClick("reply-all")}
                >
                  <span style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                    <BiReplyAll fontSize="24px" />
                    Reply All
                  </span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AllMessegesModel;
