import React, { createContext, useContext, useEffect, useState } from "react";
const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [totalCount, setTotalCount] = useState(
    () => Number(localStorage.getItem("total_count")) || 0
  );
  const token = localStorage.getItem("token");
  const getTalentDetails = async () => {
    try {
      if (!token) return;
      const response = await fetch(`${BASE_URL}/get-talent-mail-status/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.total_count != null) {
        setTotalCount(data.total_count);
        localStorage.setItem("total_count", data.total_count);
      }
    } catch (error) {
      setTotalCount(0);
      console.error("Error:", error);
    }
  };

  const createNotification = async () => {
    try {
      const response = await fetch(`${BASE_URL}/notifications-create/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return await response.json();
    } catch (error) {
      console.error("Error creating notification:", error);
    }
  };

  const getNotifications = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-notification/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return await response.json();
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };


  useEffect(() => {
    getTalentDetails();
  }, [token]);

  const handleEmailRead = () => {
    setTotalCount(prevCount => Math.max(0, prevCount - 1));
  };

  return (
    <NotificationContext.Provider value={{ totalCount, setTotalCount, handleEmailRead, createNotification, getNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
