import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
  } from '@chakra-ui/react'
  import GmailAuth from '../../GmailAuth'

const InboxModel = ({isOpen,onClose,setShowSetting} ) => {

  const handleClose =(()=>{
      
    setShowSetting(true)
    onClose()
  })
  return (
    <div>

<>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inbox</ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBody>
           <p>Click below to connect your email account and start using the inbox feature.</p>
           <GmailAuth payloadvalue={1} fetchEmails={() => {}}/>
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button> */}
            {/* <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
          
        </ModalContent>
      </Modal>
    </>
    </div>
  )
}

export default InboxModel